import logo from './Inner Bliss Collective (1).png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
                
          Coming Soon
        
      </header>
    </div>
  );
}

export default App;
